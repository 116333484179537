<template>
  <v-container fluid fill-height class="container">
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-model="errorCredentialsDialog" dismissible dense transition="scale-transition" type="error">
      خطأ في البريد الالكتروني او الرمز السري
    </v-alert>
    <v-alert v-model="errorServerDialog" type="error">
      Server Error
    </v-alert>

    <div class="title">
      <v-img src="~@/assets/gdid_logo.png" max-width="150" class="mx-auto"></v-img>

      <p class="font-weight-bold white--text text-h5" >وزارة الصناعة والمعادن</p>
      <p class="font-weight-bold white--text text-h4">
        المديرية العامة للتنمية الصناعية
      </p>

    </div>
    <div class="login-form">
      <v-card color="primary" min-width="500" min-height="550">
        <v-card-title class="pa-8 justify-center">
          <div>
            <p class="font-weight-black text-h4 text-center white--text">
              مرحبا بك في نظام
            </p>
            <p class="font-weight-black text-h5 text-center white--text">
              خريطة المشاريع الصناعية
            </p>

          </div>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              outlined
              background-color="secondary"
              dark
              label="البريد الالكتروني"
              v-model="form.email"
              :rules="emailRules"
              required
            >
              <template v-slot:prepend-inner>
                <v-icon class="mx-1" :color="'accent'">
                  mdi-account-outline
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              background-color="secondary"
              dark
              type="password"
              label="الرمز السري"
              v-model="form.password"
              :rules="passwordRules"
              required
              prepend-inner-icon="mdi-lock"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mx-1" :color="'accent'"> mdi-lock </v-icon>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="Login" rounded color="accent" dark large width="240"
            >تسجيل الدخول</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",

  components: {},
  data() {
    return {
      valid: true,
      loadingDialog:false,
      errorCredentialsDialog:false,
      errorServerDialog:false,
      emailRules: [
        (v) => !!v || "البريد الالكتروني مطلوب",
        (v) => /.+@.+\..+/.test(v) || "البريد الالكتروني غير مكتوب بصورة صحيحة",
      ],
      passwordRules: [(v) => !!v || "الرمز السري مطلوب"],
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      LogoutAction: "auth/Logout",
    }),
    Login() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true
        this.signIn(this.form)
          .then(() => {
            this.loadingDialog = false
            this.$router.replace("/MapsTypes");
          })
          .catch((e) => {
            if (
              e.response.status == 401
            ) {
              this.loadingDialog = false
              this.errorCredentialsDialog = true
            }
            if (e.response.status == 500) {
              alert("Server Error");
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>
<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 1fr;
}
.container .title {
  grid-row: 1/2;
  grid-column: 1/2;
  justify-self: center;
  text-align: center;
}
.container .login-form {
  grid-row: 2/3;
  grid-column: 1/2;
  margin-top: 8px;
  justify-self: center;
  align-self: flex-start;
}
.v-alert {
  position: fixed;
}
</style>